import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import { useAlert } from 'react-alert';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { useTranslation } from '../components/hooks/useTranslation';
import { ACTIVE } from '../helpers/colors';

const MetaTags = loadable(() => import('../components/MetaTags'));

const Status = ({
  error,
  children,
  t,
  alert,
}) => {
  let code = _.get(error, 'extensions.code', 404);
  code = +code;
  const path = _.get(error, 'extensions.path', null);
  const message = _.get(error, 'message', t('no_page', { ns: 'index' }));
  if ((code === 301 || code === 302) && path) {
    if (_.get(error, 'message')) {
      alert.show(_.get(error, 'message'), { type: 'warning' });
    }
    return <Redirect to={path} />;
  }
  return <div>{children({ status: { code, message } })}</div>;
};

const Error = ({ errors: _errors, auth }) => {
  const alert = useAlert();
  const { t } = useTranslation();
  const errors = auth ? [{
    message: t('auth_error_message', { ns: 'index' }),
    extensions: {
      code: 401,
    },
  }] : _errors;
  const error = _.get(errors, 0, null);
  let errorMessage = auth ? `${t('auth_error', { ns: 'index' })} - 401` : `${t('error', { ns: 'index' })} - 404`;
  let errorDescription = t('no_page', { ns: 'index' });
  if (!_.isEmpty(error)) {
    errorMessage = `${_.get(error, 'extensions.code', 404) === '403'
      ? t('error_access', { ns: 'index' })
      : t('error', { ns: 'index' })} - ${_.get(error, 'extensions.code', 404)}`;
    errorDescription = _.get(error, 'message', 404);
  }
  return (
    <ErrorPage>
      <MetaTags title={errorMessage} description={errorDescription} />
      {error ? (
        <Status key={_.get(error, 'extensions.code', 404)} error={error} t={t} alert={alert}>
          {({ status }) => (
            <ErrorBox>
              <H1>{_.get(status, 'code')}</H1>
              <H2>{_.get(status, 'message')}</H2>
            </ErrorBox>
          )}
        </Status>
      ) : (
        <ErrorBox>
          <H1>404</H1>
          <H2>{t('no_page', { ns: 'index' })}</H2>
        </ErrorBox>
      )}
      <LinkBtn to="/">{t('go_to_home', { ns: 'index' })}</LinkBtn>
    </ErrorPage>
  );
};

const LinkBtn = styled(Link)`
  background-color: transparent;
  color: ${ACTIVE};
  border: 1px solid ${ACTIVE};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 270px;
  padding: 20px;
  font-size: 16px;
  border-radius: 3px;
  transition: all 300ms linear;
  &:hover,
  &:focus {
    text-decoration: none;
    transition: all 300ms linear;
    background-color: ${ACTIVE};
    color: white;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
    font-size: 14px;
    height: 18px;
  }
`;

const ErrorPage = styled.section`
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  @media screen and (max-width: 786px) {
    padding: 0 20px;
  }
`;

const ErrorBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
  justify-content: center;
`;

const H1 = styled.h1`
  font-weight: 500;
  font-size: 220px;
  line-height: 1;
  margin-bottom: 0;
  color: #050436;
  @media screen and (max-width: 786px) {
    font-size: 150px;
  }
  @media screen and (max-width: 400px) {
    font-size: 130px;
  }
`;
const H2 = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  @media screen and (max-width: 786px) {
    font-size: 14px;
    text-align: center;
  }
`;
Status.defaultProps = {
  error: {},
};

Status.propTypes = {
  error: PropTypes.object,
  children: PropTypes.any.isRequired,
  t: PropTypes.func.isRequired,
  alert: PropTypes.func.isRequired,
};

Error.defaultProps = {
  auth: false,
  errors: [],
};

Error.propTypes = {
  auth: PropTypes.bool,
  errors: PropTypes.array,
};

export default Error;
